import { createSlice } from "@reduxjs/toolkit";
const initialState  = {
    state:[],
    country: [],
    district:[],
    thematicAreas:[],
    annexureList:[],
    moduleType: [],
    addressType:[],
    eventMode: [],
    eventType: [],
    eventArea: [],
    beneficiaryTypes:[],
    sdgGuidelines: [],
    sdgGuideLinesMapping: [],
    eventBeneficairy:[],
    transportationMode:[],
    langauge:[],
    managementType:[],
    pendingCall: false,
    isLoad: false,
    schedule7: [],
    colorCodes: [],
    postActivity:[],
    hashtags:[],
    mentions:[],
    tagMentions:[],
    postTypes:[],
    postReaction:[],
    grievanceTypes:[],
    connectionTypes:[],
    rfpQuestions:[],
    requestTypes: [],
    nominationTypes:[],
    pocTypes:[],
    profileTypes: [],  
    fieldList: [], 
}
const masterSlice = createSlice({
    name:"master",
    initialState,
    reducers: {
        resetMasterSlice: () => initialState,
        addAnnexureList(state, action){
            state.annexureList = action.payload;
        },
        addThematicAreas(state, action){
            state.thematicAreas = action.payload;
        },
        addUserState(state, action){
            state.state = action.payload;
        },
        addUserDistrict(state, action){
            state.district = action.payload;
        },
        addUserCountry(state, action){
            state.country = action.payload;
        },
        addAddressType(state, action){
            state.addressType = action.payload;
        },
        addModuleType(state, action){
            state.moduleType = action.payload;
        },
        addEventMode(state, action){
            state.eventMode = action.payload;
        },
        addEventArea(state, action){
            state.eventArea = action.payload;
        },
        addEventType(state, action){
            state.eventType = action.payload;
        },
        addBeneficiaryTypes(state, action){
            state.beneficiaryTypes = action.payload;
        },
        addSdgGuideLines(state, action){
            state.sdgGuidelines = action.payload;
        },
        addSdgGuideLinesMapping(state, action){
            state.sdgGuideLinesMapping = action.payload;
        },
        resetSdgGuideLines(state) {
            state.sdgGuideLinesMapping = [];
        },
        addEventBeneficiaryTypes(state, action){
            state.eventBeneficairy = action.payload;
        },
        addTransportaionMode(state, action){
            state.transportationMode = action.payload;
        },
        addLanguage(state, action){
            state.langauge = action.payload;
        },
        addManagementType(state, action){
            state.managementType = action.payload;
        },
        setPendingCall(state, action){
            state.pendingCall = action.payload;
        },
        setIsLoad(state, action){
            state.isLoad = action.payload;
        },
        addSchedule7(state, action){
            state.schedule7 = action.payload;
        },
        addColorCodes(state, action){
            state.colorCodes = action.payload;
        },
        setPostActivity(state, action){
            state.postActivity = action.payload;
        },
        setMentions(state, action) {
            state.mentions = action.payload
        },
        setTagMentions(state, action) {
            state.tagMentions = action.payload
        },
        setHastags(state, action) {
            state.hashtags = action.payload
        },
        setPostTypes(state, action) {
            state.postTypes = action.payload
        },
        setPostReaction(state, action) {
            state.postReaction = action.payload
        },
        setGrievanceTypes(state, action) {
            state.grievanceTypes = action.payload
        },
        setConnectionTypes(state, action) {
            state.connectionTypes = action.payload
        },
        setRfpQuestions(state, action) {
            state.rfpQuestions = action.payload
        },
        setRequestTypes(state, action) {
            state.requestTypes = action.payload
        },
        setNominationTypes(state, action) {
            state.nominationTypes = action.payload
        },
        setProfileTypes(state, action) {
            state.profileTypes = action.payload
        },
        setPocTypes(state, action) {
            state.pocTypes = action.payload
        },
        setFieldList(state, action) {
            state.fieldList = action.payload
        },
    }
})

export default masterSlice.reducer;
export const { 
    addEventBeneficiaryTypes,
    addTransportaionMode,
    addAnnexureList,
    addAddressType,
    addUserDistrict,
    addUserState,
    addUserCountry,
    addThematicAreas,
    resetMasterSlice,
    addEventMode,
    addEventArea,
    addEventType,
    addBeneficiaryTypes,
    addSdgGuideLines,
    addSdgGuideLinesMapping,
    resetSdgGuideLines,
    addLanguage,
    addManagementType,
    setPendingCall,
    setIsLoad,
    addSchedule7,
    addColorCodes,
    setPostActivity,
    addModuleType,
    setHastags,
    setMentions,
    setTagMentions,
    setPostTypes,
    setPostReaction,
    setGrievanceTypes,
    setConnectionTypes,
    setRfpQuestions,
    setRequestTypes,
    setNominationTypes,
    setProfileTypes,
    setPocTypes,
    setFieldList,
}  = masterSlice.actions;