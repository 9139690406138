import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import { Autocomplete, Box, MenuItem, Popper, styled, TextField } from '@mui/material';
import theme from '../../assets/material-ui/theme';
import { getEventDistrict } from '../../modules/BEngage/actions';
import { useDispatch } from 'react-redux';

// Create a custom Popper component to style the dropdown
const StyledPopper = styled(Popper)(({ theme }) => ({
    minWidth: '330px',
    maxHeight: '224px',
    width: '200px',
    backgroundColor:"white",
    '& .MuiAutocomplete-paper': {
        minWidth: '330px',
        maxHeight: '224px',
        transition: 'opacity 289ms cubic-bezier(0.4, 0, 0.2, 1), transform 192ms cubic-bezier(0.4, 0, 0.2, 1)',
    },
    '& .MuiAutocomplete-listbox': {
        overflow: 'unset'
    }
    // transition: 'opacity 289ms cubic-bezier(0.4, 0, 0.2, 1), transform 192ms cubic-bezier(0.4, 0, 0.2, 1)',
}));

export const AutoSelectField = ({
    form,
    input,
    label,
    children,
    options,
    placeholder,
    meta,
    disabled,
    customHeight,
    placeholderFontSize,
    placeholderFontWeight,
    style,
    radius,
    width,
    square,
    PaperPropsStyle,
}) => {
    const dispatch = useDispatch();
    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: width || 200,
                ...PaperPropsStyle,
            },
        },
    };
    const id = `${form}_${input?.value?.key?.replace('.', '/_/g')}`;
    // Ensure correct initial value is set in Autocomplete
    const initialValue = options.find((option) => option?.id === input?.value) || null;
    useEffect(() => {
        if (initialValue?.id && input?.name === 'state_id') {
            dispatch(getEventDistrict(initialValue?.id));
        }
    }, [])

    return (
        <FormControl fullWidth className="common-select">
            <InputLabel htmlFor={id} sx={{ fontSize: placeholderFontSize, fontWeight: placeholderFontWeight ? placeholderFontWeight : 400 }}>{label}</InputLabel>
            <Autocomplete
                value={initialValue}
                disabled={disabled}
                disableClearable
                options={options} // Autocomplete options
                PopperComponent={StyledPopper}
                getOptionLabel={(option) => option?.value || ""}
                isOptionEqualToValue={(option, value) => option.id === value.id} // Compare by id
                onChange={(event, newValue) => input.onChange(newValue ? newValue.id : '')}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label={label}
                        placeholder={placeholder}
                        variant="outlined"
                        inputProps={{
                            ...params.inputProps,
                            style: {
                                fontSize: placeholderFontSize,
                                fontWeight: placeholderFontWeight ? placeholderFontWeight : 400,
                                // height: customHeight ? customHeight : 50,
                                color: input.value ? '#000000DE' : '#666',
                                padding: 0
                            },
                        }}
                    />
                )}
                renderOption={(props, option, { selected, index }) => (
                    <Box
                        component="li"
                        {...props}
                        sx={{
                            borderTop: index !== 0 ? '1px solid grey' : 'none',
                            paddingLeft: '16px',
                            paddingRight: '16px',
                            paddingTop: '6px',
                            paddingBottom: '6px',
                            fontSize: '12px',
                            whiteSpace: 'normal',
                            wordWrap: 'break-word',
                            backgroundColor:"white"
                        }}
                    >
                        {option.value}
                    </Box>
                )}
                sx={{
                    borderRadius: radius || '40px !important',
                    height: customHeight ? customHeight : 50,
                    marginBottom: '2px',
                    '& .MuiAutocomplete-inputRoot': {
                        padding: '11.5px 14px !important', // Adjust Autocomplete root padding
                        borderRadius: radius || '40px !important',
                        height: customHeight ? customHeight : 50,
                        backgroundColor:"white"
                    },
                    '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
                        borderColor: square ? theme.palette.primary.dark : theme.palette.primary.main,
                    },
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                        transition: square ? 'border-color 0.3s ease' : "",
                        borderColor: square ? theme.palette.primary.dark : theme.palette.success.dark,
                    },
                    ...style,
                }}
            />
            {meta.touched &&
                (meta.error && (
                    <Box className='error'>
                        {meta.error}
                    </Box>
                ))}
        </FormControl>
    );
};

AutoSelectField.propTypes = {
    form: PropTypes.string,
    input: PropTypes.object.isRequired,
    children: PropTypes.oneOfType([
        PropTypes.node,
        PropTypes.arrayOf(PropTypes.node),
    ]).isRequired,
    meta: PropTypes.object.isRequired,
    disabled: PropTypes.bool,
    placeholderFontSize: PropTypes.string,

};
