import { Box, Button, IconButton, Typography } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { convertHashTagToLink, convertMentionsToLinks, convertUrlsToLinks, getDate, getResponseDate, getThematicNameById, isJSON } from '../../../../common/utils';
import SocialMediaCSS from '../../SocialMediaCSS/SocialMedia.module.css';
import { getHashtagData } from '../../actions';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import CommonButton2 from '../../../../common/components/CommonButton2';
import slugify from 'slugify';
import ResharePostDelCard from './PostSubComponents/ResharePostDelCard';
import ImageViewer from 'react-simple-image-viewer';
import CloseIcon from '@mui/icons-material/Close';

const PostContent = ({ post, sharedRFP, isLoggedIn }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [showFullText, setShowFullText] = useState(false);
    const [showFullText2, setShowFullText2] = useState(false);
    const [showViewMore, setShowViewMore] = useState(false);
    const [showViewMore2, setShowViewMore2] = useState(false);
    const [currentImage, setCurrentImage] = useState(0);
    const [isViewerOpen, setIsViewerOpen] = useState(false);
    const postContentRef = useRef(null);
    const postContentRef2 = useRef(null);
    const viewMoreButtonRef = useRef(null);
    const viewMoreButtonRef2 = useRef(null);
    // const hashtagRef = useRef(null);
    // const postBody = post?.body && convertHashTagToLink(convertMentionsToLinks(isJSON(post?.body) ? JSON.parse((post?.body)) : post?.body,SocialMediaCSS.contentHashtag), SocialMediaCSS.contentHashtag);
    const postBody = post && convertUrlsToLinks(convertHashTagToLink(convertMentionsToLinks(isJSON(post) ? JSON.parse((post)) : post, SocialMediaCSS.contentHashtag), SocialMediaCSS.contentHashtag));

    const handleHashtagClick = (event, hashtag) => {
        event.preventDefault();
        event.stopPropagation();
        if (isLoggedIn) {
            dispatch(getHashtagData(hashtag, navigate));
            navigate(`/social-media/hashtags/${hashtag}`);
        } else {
            navigate('/login');
        }
    };


    useEffect(() => {
        window.handleHashtagClick = handleHashtagClick;
        return () => {
            delete window.handleHashtagClick;
        };
    }, []);

    useEffect(() => {
        if (postContentRef.current) {
            const { scrollHeight, clientHeight } = postContentRef.current;
            setShowViewMore(scrollHeight > clientHeight);
        }
    }, [post]);

    useEffect(() => {
        if (postContentRef2.current) {
            const { scrollHeight, clientHeight } = postContentRef2.current;
            setShowViewMore2(scrollHeight > clientHeight);
        }
    }, [sharedRFP?.shared_module?.event_description, postContentRef2.current]);

    const toggleFullText = (e) => {
        e.stopPropagation();
        setShowFullText((prevShowFullText) => !prevShowFullText);
    };

    const toggleFullText2 = (e) => {
        e.stopPropagation();
        setShowFullText2((prevShowFullText) => !prevShowFullText);
    };

    const handleViewConference = (post, event) => {
        if (event.target.closest('.hashtag')) return;
        event.stopPropagation();
        if (isLoggedIn) {
            // Create slug from title
            const slug = slugify(post?.event_name, { lower: true, strict: true, remove: /[*+~.()'"!:@]/g });
            const slugUrl = `${slug}-${post?.conferenec_gid}`;
            navigate(`/volunteer/${slugUrl}`);
        } else {
            navigate('/login');
        }
    }
    const handleParentPost = (e) => {
        if (e.target.closest('.hashtag')) return;
        if (isLoggedIn) {
            if (sharedRFP?.multiple_shared_post || sharedRFP?.shareable) {
                if (sharedRFP?.post_gid) {
                    navigate(`/social-media/posts/${sharedRFP?.post_gid}`)
                }
            }
        } else {
            navigate('/login');
        }
    }
    const handleConferenceClick = (post) => {
        if (isLoggedIn) {
            if (sharedRFP?.shareable && sharedRFP?.shared_module?.conferenec_gid) {
                const slug = slugify(post?.shared_module?.event_name, { lower: true, strict: true, remove: /[*+~.()'"!:@]/g });
                const slugUrl = `${slug}-${post?.shared_module?.conferenec_gid}`;
                navigate(`/volunteer/${slugUrl}`);
            } else if (sharedRFP?.shareable && sharedRFP?.shared_module?.event_gid) {
                navigate(`/social-media/events/${sharedRFP?.shared_module?.event_gid}`)
            }
        }
    }
    // const decodeHTMLEntities = (text) => {
    //     let decodedString = text;
    //     const parser = new DOMParser();
    //     while (decodedString?.includes('&')) {
    //         decodedString = parser.parseFromString(decodedString, 'text/html').documentElement.textContent;
    //     }
    //     return decodedString;
    // };
    // // Function to strip HTML tags using regular expressions
    // const stripHtmlTags = (html) => {
    //     return html.replace(/<\/?[^>]+(>|$)/g, "");
    // };
    // const getTextFromHtml = (html) => {
    //     const decodedHtml = decodeHTMLEntities(html);
    //     return stripHtmlTags(decodedHtml);
    // };
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = sharedRFP?.shared_module?.event_description;
    const description = tempDiv.textContent || tempDiv.innerText || "";

    // const toggleFullText = () => {
    //     setShowFullText((prevShowFullText) => {
    //         const newShowFullText = !prevShowFullText;

    //         if (newShowFullText) {
    //             setTimeout(() => {
    //                 postContentRef.current.scrollIntoView({ behavior: 'smooth' });
    //             }, 0);
    //         } else {
    //             setTimeout(() => {
    //                 viewMoreButtonRef.current.scrollIntoView({ behavior: 'smooth' });
    //             }, 0);
    //         }

    //         return newShowFullText;
    //     });
    // };

    const openImageViewer = (index) => {
        setCurrentImage(index);
        setIsViewerOpen(true);
    };
    const closeImageViewer = () => setIsViewerOpen(false);
    const CustomCloseComponent = () => (
        <IconButton
            onClick={closeImageViewer}
            style={{ position: 'relative', top: 2, right: 10, color: '#fff', zIndex: 1000 }}
        >
            <CloseIcon fontSize='large' />
        </IconButton>
    );
    const imageUrls = [sharedRFP?.shared_module?.certificate_image]?.filter(Boolean);
    return (
        <>
            <Box className={SocialMediaCSS.postContentBox}>
                {/* <Typography variant='body2' className={SocialMediaCSS.textContent}>
                    <p dangerouslySetInnerHTML={{ __html: postBody }} />
                </Typography> */}
                <Box className={`${sharedRFP?.multiple_shared_post || sharedRFP?.shareable ? SocialMediaCSS.cursor : ''}`} onClick={(e) => handleParentPost(e)}>
                    <Typography variant='body2' className={SocialMediaCSS.textContent} ref={postContentRef} style={{ maxHeight: showFullText ? 'none' : '55px', overflow: 'hidden' }}>
                        <p dangerouslySetInnerHTML={{ __html: postBody }} />

                    </Typography>
                    {showViewMore && (
                        <Button onClick={(e) => toggleFullText(e)} className={SocialMediaCSS.viewMoreButton} ref={viewMoreButtonRef} style={{ display: 'block', marginTop: '15px' }}>
                            {showFullText ? 'View Less' : 'View More'}
                        </Button>
                    )}</Box>
                {sharedRFP?.deleted_content === true &&
                    <ResharePostDelCard />}
                {sharedRFP?.shared_module?.rfp_title &&
                    <Box width='100%' className={SocialMediaCSS.cursor} mt={-4} onClick={() => isLoggedIn ? navigate(`/social-media/rfp/${sharedRFP?.shared_module?.rfp_gid}`) : navigate('/login')}>
                        <Box className={`${SocialMediaCSS.f} ${SocialMediaCSS.whiteSpace}`} gap={1} >
                            {sharedRFP?.shared_module?.rfp_thematic_area_ids?.map((theme, index) => (
                                <Box className={SocialMediaCSS.rfpThematic} mt={1.6} key={index}>
                                    <Typography className={SocialMediaCSS.themeRfp}>{dispatch(getThematicNameById(theme))}</Typography>
                                </Box>
                            ))}
                        </Box>
                        <Box pt={1} mt={1}>
                            <Typography className={SocialMediaCSS.textContent} >{sharedRFP?.shared_module?.rfp_title}</Typography>
                        </Box>

                        <Box className={`${SocialMediaCSS.f} ${SocialMediaCSS.jc_space_between}`} pt={2}>
                            < CommonButton2
                                label="View RFP"
                                name="reshare"
                                variant="outlined"
                                type="button"
                                onClick={() => isLoggedIn ? navigate(`/social-media/rfp/${sharedRFP?.shared_module?.rfp_gid}`) : navigate('/login')}
                                style={{ padding: '6px 16px', fontSize: '14px', height: '32px', fontWeight: '600' }}
                            />

                            <Box className={`${SocialMediaCSS.f} ${SocialMediaCSS.ai_center} ${SocialMediaCSS.jc_end}`} >
                                <Typography className={SocialMediaCSS.rfpLast}>Last submission Date:</Typography> &nbsp;
                                <Typography className={SocialMediaCSS.LaRfpDate}>{getDate(sharedRFP?.shared_module?.submission_date)}</Typography>
                            </Box>
                        </Box>

                    </Box>
                }
                {sharedRFP?.shared_module?.event_name &&
                    <Box className={`${SocialMediaCSS.f} ${SocialMediaCSS.fd_column} ${SocialMediaCSS?.cursor}`} width="100%" onClick={() => handleConferenceClick(sharedRFP?.shared_module)}>
                        <Box>
                            <img src={sharedRFP?.shared_module?.event_image} className={SocialMediaCSS.eventSharImage} />
                        </Box>
                        <Box className={`${SocialMediaCSS.f} ${SocialMediaCSS.jc_space_between}`}>

                            {/* <Typography className={SocialMediaCSS.rfpshrthmEvent}>{sharedRFP?.shared_module?.event_thematic_area}</Typography> */}
                            <Box className={SocialMediaCSS.rfpshrthmEvent} sx={{ display: "flex" }}>
                                {Array.isArray(sharedRFP?.shared_module?.thematic_areas) ? (
                                    <>
                                        {/* Display the first thematic area from the array */}
                                        <Typography className={SocialMediaCSS.rfpshrthmEvent}>
                                            {sharedRFP?.shared_module?.thematic_areas[0]}
                                        </Typography> &nbsp;

                                        {/* Only show +N if there are more than 1 thematic areas */}
                                        {sharedRFP?.shared_module?.event_thematic_areas?.length > 1 && (
                                            <Box className={SocialMediaCSS.plustheme}>
                                                <Typography className={SocialMediaCSS.plusText}>
                                                    +{sharedRFP?.shared_module?.thematic_areas?.length - 1}
                                                </Typography>
                                            </Box>
                                        )}
                                    </>
                                ) : (
                                    /* If it's a string, just display it */
                                    <Typography className={SocialMediaCSS.rfpshrthmEvent}>
                                        {sharedRFP?.shared_module?.thematic_areas || 'N/A'}
                                    </Typography>
                                )}
                            </Box>
                            <Typography className={SocialMediaCSS.rfpshrEvent}>{getResponseDate(sharedRFP?.shared_module?.event_start_date)}</Typography>
                        </Box>
                        <Box pt={1}>
                            <Typography className={SocialMediaCSS.textContent} >{sharedRFP?.shared_module?.event_name}</Typography>
                        </Box>
                        {/* <Box pt={1}>
                            <Typography className={SocialMediaCSS.rfpshrEvent} ref={postContentRef2} style={{ maxHeight: showFullText2 ? 'none' : '50px', overflow: 'hidden' }} >{description}</Typography> */}
                            {/* <Typography className={SocialMediaCSS.rfpshrEvent} ref={postContentRef2} style={{ maxHeight: showFullText2 ? 'none' : '50px', overflow: 'hidden' }} >{getTextFromHtml(sharedRFP?.shared_module?.event_description)}</Typography> */}

                            {/* {showViewMore2 && (
                                <Button onClick={(e) => toggleFullText2(e)} className={SocialMediaCSS.viewMoreButton} ref={viewMoreButtonRef2} style={{ display: 'block', marginTop: '15px' }}>
                                    {showFullText2 ? 'View Less' : 'View More'}
                                </Button>
                            )}
                        </Box> */}
                        {/* {sharedRFP?.shared_module?.event_mode !== 'null' && */}
                        <Box pt={0.5} className={`${SocialMediaCSS.f}`}>
                            <Typography className={SocialMediaCSS.rfpshrEvent} sx={{ whiteSpace: 'nowrap' }}>{sharedRFP?.shared_module?.event_mode}</Typography> &nbsp;
                        </Box>
                        <Box className={`${SocialMediaCSS.f} ${SocialMediaCSS.jc_space_between}`} pt={2}>
                            {sharedRFP?.shared_module?.event_gid &&
                                <Typography className={SocialMediaCSS.rfpshrEvent}>{sharedRFP?.shared_module?.event_attendance} attendance</Typography>}
                            {sharedRFP?.shared_module?.conferenec_gid &&
                                <Typography className={SocialMediaCSS.rfpshrEvent}></Typography>}

                            {sharedRFP?.shared_module?.event_gid &&
                                < CommonButton2
                                    label="View Event"
                                    name="viewEvent"
                                    variant="outlined"
                                    type="button"
                                    onClick={() => isLoggedIn ? navigate(`/social-media/events/${sharedRFP?.shared_module?.event_gid}`) : navigate('/login')}
                                    style={{ padding: '6px 16px', fontSize: '14px', height: '32px', fontWeight: '600' }}
                                />}
                            {sharedRFP?.shared_module?.conferenec_gid &&
                                < CommonButton2
                                    label="View Conference"
                                    name="viewConference"
                                    variant="outlined"
                                    type="button"
                                    onClick={(event) => handleViewConference(sharedRFP?.shared_module, event)}
                                    style={{ padding: '6px 16px', fontSize: '14px', height: '32px', fontWeight: '600' }}
                                />}
                        </Box>
                    </Box>
                }
                {sharedRFP?.shared_module?.certificate_image &&
                    <Box onClick={() => openImageViewer()}>
                        <img src={sharedRFP?.shared_module?.certificate_image} className={SocialMediaCSS.eventSharImage} />
                    </Box>
                }

                {/* <Typography variant='body4' className={SocialMediaCSS.contentHashtag}>
                    #SocialImpact
                </Typography>*/}
            </Box >
            {isViewerOpen && imageUrls?.length > 0 && (
                <div sx={{ overflow: 'hidden' }}>
                    <ImageViewer
                        src={imageUrls}
                        currentIndex={currentImage}
                        onClose={closeImageViewer}
                        disableScroll={true}
                        closeOnClickOutside={true}
                        backgroundStyle={{
                            //backgroundColor: 'rgba(0,0,0,0.9)',
                            zIndex: 9999,
                            marginTop: "40px",
                        }}

                        closeComponent={<CustomCloseComponent />}
                    />
                </div>
            )}
        </>
    )
}

export default PostContent
