import React from "react";
import CommonSelect from './CommonSelect';
import { MenuItem } from "@mui/material";

const SingleSelect = (props) => {
  const { placeholderFontSize } = props;
  const handleOnChange = (event, value) => {
    if (props.onChange) {
      props.onChange(value, props.name);
    }
  };

  return (
    <>
      <CommonSelect
        name={props.name}
        validate={props.validate}
        label={props.label}
        value={props.value}
        square={props.square}
        onChange={handleOnChange}
        disabled={props.disabled}
        defaultValue={props.defaultValue}
        customHeight={props.customHeight}
        radius={props.radius}
        placeholder={props.placeholder}
        placeholderFontSize={props.placeholderFontSize}
        placeholderFontWeight={props.placeholderFontWeight}
        checkValue={props.checkValue}
        type={props.type}
        options={props.options}
        clockIcon={props.clockIcon}
      // {...props}
      >
        <MenuItem value="" disabled sx={{ fontSize: props.placeholderFontSize }}>{props.placeholder}</MenuItem>
        {!props.brandList && props.isProfile && !props.isSupport && props.options && props.options.map((data) => (
          <MenuItem
            style={{ borderTop: "1px solid grey" }}
            value={data.id}
            key={data.id}
            title={data.value}
            sx={{ whiteSpace: 'normal !important', wordWrap: 'break-word', fontSize: placeholderFontSize, backgroundColor: "white" }}
            disabled={props?.checkValue && props?.checkValue >= data.id ? true : false}
          >
            {data.value}
          </MenuItem>
        ))}
        {!props.brandList && !props.isProfile && props.isSupport && props.options && props.options.map((data) => (
          <MenuItem
            style={{ borderTop: "1px solid grey" }}
            value={data.value}
            key={data.value}
            title={data.value}
            sx={{ whiteSpace: 'normal !important', wordWrap: 'break-word', fontSize: placeholderFontSize, backgroundColor: "white" }}
            disabled={props?.checkValue && props?.checkValue >= data.id ? true : false}
          >
            {data.value}
          </MenuItem>
        ))}
        {!props.brandList && !props.isProfile && !props.isSupport && !props.isReg && !props.dynamicForm && props.options && props.options.map((data) => (
          <MenuItem
            style={{ borderTop: "1px solid grey" }}
            value={data.id}
            key={data.id}
            title={data.name || data.value}
            sx={{ whiteSpace: 'normal !important', wordWrap: 'break-word', fontSize: placeholderFontSize, backgroundColor: "white" }}
            disabled={props?.checkValue && props?.checkValue >= data.id ? true : false}
          >
            {data.name || data.value}
          </MenuItem>
        ))}
        {!props.brandList && !props.isProfile && !props.isSupport && props.isReg && props.options && props.options.map((data) => (
          <MenuItem
            style={{ borderTop: "1px solid grey" }}
            value={data.key}
            key={data.key}
            title={data.name || data.value}
            sx={{ whiteSpace: 'normal !important', wordWrap: 'break-word', fontSize: placeholderFontSize, backgroundColor: "white" }}
            disabled={props?.checkValue && props?.checkValue >= data.id ? true : false}
          >
            {data.name || data.value}
          </MenuItem>
        ))}
        {props.brandList && props.options && props.options.map((data) => (
          <MenuItem
            style={{ borderTop: "1px solid grey" }}
            value={data.company_id}
            key={data.company_id}
            title={data.name}
            sx={{ whiteSpace: 'normal !important', wordWrap: 'break-word', textTransform: 'capitalize', display: 'flex', justifyContent: 'space-between', fontSize: placeholderFontSize, backgroundColor: "white" }}
          >
            <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
              <div style={{ overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '70%', wordWrap: 'break-word' }}>{data.name}</div>
              <div>{`${data.gid}  `}</div>
            </div>
          </MenuItem>
        ))}
        {props.dynamicForm && props.options && props.options.map((data) => (
          <MenuItem
            style={{ borderTop: "1px solid grey" }}
            value={data}
            key={data}
            title={data}
            sx={{ whiteSpace: 'normal !important', wordWrap: 'break-word', textTransform: 'capitalize', display: 'flex', justifyContent: 'space-between', fontSize: placeholderFontSize, backgroundColor: "white" }}
          >
            <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
              <div style={{ overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '70%', wordWrap: 'break-word' }}>{data}</div>
            </div>
          </MenuItem>
        ))}
      </CommonSelect>
    </>
  );
};

export default SingleSelect;