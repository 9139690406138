import React, { useEffect, useState } from "react";
import { Box, InputAdornment, OutlinedInput, Typography } from "@mui/material";

export const RenderField = ({
  input,
  label,
  placeholder,
  type,
  fullWidth,
  maxLength,
  underlineStyle,
  errorStyle,
  style,
  toolTiptext,
  id,
  pattern,
  defaultValue,
  disabled,
  radius,
  min,
  max,
  value,
  onChange,
  errorWidth,
  onKeyPress,
  meta: { touched, error, warning },
  inputProps,
  errorMessages,
  custom,
  placeholderFontSize,
  placeholderFontWeight,
  customHeight,
  classes = {},
  onDrop,
  onDragOver,
  showCount,
  countFontSize,
  endAdornment
}) => {
  let inputType = type;
  const [charCount, setCharCount] = useState(input.value ? input.value.length : 0);
  errorStyle = {
    fontSize: "10px",
    color: "rgb(244, 67, 54)",
    position: "static",
  };

  const handleChange = (event) => {
    const inputValue = event.target.value;
    const currentLength = inputValue.length;
    setCharCount(currentLength); // Update charCount with the current length of input value
    input.onChange(inputValue); // Call the onChange function passed through props to update the form value
};

  useEffect(() => {
    // Update charCount when input value changes
    setCharCount(input.value ? input.value.length : 0);
  }, [input.value]);

  return (
    <>
      <OutlinedInput
        inputProps={{
          maxLength,
          disabled,
          min: 0,
          ...inputProps // ensure other inputProps are passed
        }}
        className={`TextField ${classes.root || ""}`}
        sx={{
          height: customHeight ? customHeight : 50,
          borderRadius: radius ? `${radius} !important` : 40,
          backgroundColor: (theme) => theme.palette.secondary.dark,
          "& input::placeholder": {
            fontSize: placeholderFontSize,
            fontWeight: placeholderFontWeight,
          },
          "& .MuiOutlinedInput-input": {
            fontSize: placeholderFontSize,
          },
        }}
        endAdornment={endAdornment && (
          <InputAdornment position="end">{endAdornment}</InputAdornment>
        )}
        placeholder={placeholder}
        id={id}
        disabled={disabled}
        autoComplete="off"
        pattern={pattern}
        type={inputType}
        onKeyPress={onKeyPress}
        onDragOver={onDragOver}
        onDrop={onDrop}
        fullWidth={fullWidth}
        value={defaultValue}
        onChange={handleChange}
        min={min}
        max={max}
        style={style}
        {...input}
        {...custom}
      />
      <Box sx={{ display: "flex", paddingTop: '6px' }}>
        {touched && error && (
          <Box
            style={{
              color: "red",
              fontSize: "12px",
              padding: 0,
              paddingLeft: "10px",
              wordBreak: "break-word",
              lineHeight: "14px",
              maxWidth: errorWidth ? errorWidth : "400px",
            }}
          >
            {error}
          </Box>
        )}
        {showCount && (
          // <Box sx={{ display: 'flex', justifyContent: 'flex-end',alignItems:'end' }}>
          <Typography sx={{ fontSize: countFontSize ? countFontSize : '10px', color: '#666', fontWeight: "500", marginLeft: 'auto', fontStyle: 'italic' }}>{`${charCount}/${maxLength}`}</Typography>
          // </Box>
        )}
      </Box>
    </>
  );
};
export default RenderField;
