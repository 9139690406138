import React, { useEffect } from 'react'
import { Box, Grid, Typography, useMediaQuery } from '@mui/material'
// import CustomSnackbar from '../../../common/components/CommonSnackbar'
import { useSelector } from 'react-redux'
import { useLocation, useNavigate, useParams } from 'react-router'
import RegCSS from '../../modules/Registration/common/RegistrationCSS/RegCSS.module.css';
import MainCarousel from '../../modules/Registration/components/MainCarousel';
import GetPlayStore from '../../assets/images/GetPlayStore.svg';
import GetApple from '../../assets/images/GetApple.svg'
import AppleQr from '../../assets/images/AppleQr.svg';
import PlayStoreQr from '../../assets/images/PlayStoreQr.svg'
import heroBg from "../../assets/images/heroBg.svg";
import PublicHeader from './PublicHeader';
import AppStore from '../../assets/images/Download_App_Store.svg';
import PageLoader from './PageLoader';
import { useDispatch } from 'react-redux';
import { emailAcceptDecline } from '../../modules/BEngage/actions';
import CarouselSpeakerInvitation from './CarouselSpeakerInvitation';

const InvitationMessagePage = (props) => {
    const snackbarProps = useSelector((state) => state.message);
    const { bEngage: { statusSpeaker } } = useSelector((state) => state);
    const users = useSelector((state) => state.users);
    const navigate = useNavigate();
    const location = useLocation();
    const params = useParams();
    const dispatch = useDispatch();
    const isMobile = useMediaQuery('(max-width: 960px)');
    useEffect(() => {
        if (users.isLoggedIn) {
            // navigate("/user-dashboard");
        }
    }, [])

    useEffect(() => {
        window.scrollTo(0, 0);
        dispatch(emailAcceptDecline(params?.uniqueId, params?.invitation_gid, params?.conference_gid))
    }, []);
    // console.log("ready",params?.uniqueId, params?.invitation_gid)
    let status = statusSpeaker;
    let message = {};
    switch (status) {
        case "You have Already Declined":
            message = {
                text1: "You Have Already",
                text2: "Declined",
            };
            break;
        case "You Have Already Accepted":
            message = {
                text1: "You Have Already",
                text2: "Accepted",
            };
            break;
        case "Thank You For Your Feedback":
            message = {
                text1: "Thank you for your",
                text2: "Feedback",
            };
            break;
        case "Thank You For Registering":
            message = {
                text1: "Thank you for",
                text2: "Registering",
            };
            break;
        case "You Have Already Registered":
            message = {
                text1: "You Have Already",
                text2: "Registered",
            };
            break;
        case "You Have Already withdraw":
            message = {
                text1: "You Have Already",
                text2: "Withdrawn",
            };
            break;
        default:
            message = {
                text1: "Unknown Status",
                text2: "",
            };
            break;
    }
    return (
        <>
            <PageLoader open={statusSpeaker === ""} />
            <Grid container rowSpacing={3} className={RegCSS.newStatusLayoutRoot2} sx={{ paddingBottom: { xs: '120px', sm: '48px', md: '0px' } }}>
                <PublicHeader />
                <Grid item xs={12} md={7} lg={6} className={RegCSS.statusRegLayout2} sx={{ justifyContent: { xs: 'center', md: 'start' }, }} >
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <Box pl={9}>
                            <Box className={RegCSS.newRegLayoutBox} >
                                {/* {props.children} */}
                                <Box sx={{ paddingTop: isMobile ? "50px" : "0px" }}>
                                    <Typography className={RegCSS.statusRegtext1}>{message.text1}</Typography>
                                    <Typography className={RegCSS.statusRegtext2}>{message.text2}</Typography>

                                    <Typography className={RegCSS.statusRegtext3}>Scan the QR code to download our app for social community
                                        and collaboration, RFP's, events and industry updates.
                                    </Typography>
                                    {!isMobile && (
                                        <Box className={RegCSS.statusRegQR} pt={4}>
                                            <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", gap: 2 }} >
                                                <img src={PlayStoreQr} height="150px" width="150px" />
                                                <a href="https://play.google.com/store/apps/details?id=com.bvokal.bvokal" target="_blank">
                                                    <img src={GetPlayStore} height="44.5px" width="150px" />
                                                </a>
                                            </Box>
                                            <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", gap: 2 }} >
                                                <img src={AppleQr} height="150px" width="150px" />
                                                <a href="https://apps.apple.com/in/app/bvokal/id6466199235" target="_blank">
                                                    <img src={GetApple} height="44.5px" width="150px" />
                                                    {/* <img src={AppStore} height="44.5px" width="150px" /> */}
                                                </a>
                                            </Box>
                                        </Box>
                                    )}
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={12} md={5} lg={6} className={RegCSS.StatusLayoutGrid}>
                    <Box sx={{ paddingTop: "20%" }}>
                        <CarouselSpeakerInvitation />
                    </Box>
                </Grid>
            </Grid>
            {/* {Object.keys(snackbarProps)?.length > 0 && <Box textAlign='center' width='100%' ><CustomSnackbar /> </Box>}
            <Box className={RegCSS.newRegLayoutGrid3}>
                <LightFooterNew />
            </Box> */}
        </>
    )
}

export default InvitationMessagePage

