import React, { lazy, useEffect } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { updateLoginDataFromCookies } from "./modules/Registration/actions";
import { setModalDetails } from "./common/actions";
import { moveToSocialMedia } from "./common/utils";
import UserData from "./common/components/UserData";
import PermissionDialog from '../src/common/components/permissionDialog';
import NewRegistrationComponent from "./modules/Registration/NewRegistrationComponent";
import SocialMediaComponent from "./modules/SocialMedia";
import SocialMediaDashboard from "./modules/SocialMedia/Components/SocialMediaDashboard";
import Events from "./modules/SocialMedia/Components/Events/Events";
import EventDetailsNewTab from './modules/SocialMedia/Components/Events/Subcomponents/EventDetailsNewTabPreview';
import Schedule7Layout from "./modules/SocialMedia/Components/Schedule7Layout";
import RfpsListing from "./modules/SocialMedia/Components/Rfps/RfpsListing";
import RfpPreview from "./modules/SocialMedia/Components/Rfps/RfpPreview";
import ChatsLayout from "./modules/SocialMedia/Components/Chats/ChatsLayout";
import Notification from "./modules/SocialMedia/Components/Notifications/Notification";
import CommunityLayout from "./modules/SocialMedia/Components/Community/CommunityLayout";
import HelpAndSupport from "./common/components/HelpAndSupport";
import SocialBengage from "./modules/SocialMedia/Components/BEngange/SocialBengage";
import AboutUs from "./common/components/AboutUs";
import Contact from "./common/components/Contact";
import Security from "./common/components/Security";
import BookMark from "./modules/SocialMedia/Components/BookMarks/BookMark";
import NotificationPost from "./modules/SocialMedia/Components/Posts/NotificationPosts";
import MyPost from "./modules/SocialMedia/Components/Posts/MyPost";
import RedirectBEngage from "./modules/BEngage/Components/RedirectBEngage";
import InvitationMessagePage from "./common/components/InvitationMessagePage";
import CommonRedirectPage from "./common/CommonRedirectPage";



const UserDashboard = lazy(() => import('./modules/Profile/components/UserDashboard'));
const Logout = lazy(() => import("./modules/Registration/components/Logout"));
const CommonErrorPage = lazy(() => import("./common/CommonErrorPage"));
const TermsAndCondition = lazy(() => import("./common/components/TermsAndCondition"));
const UserSaftey = lazy(() => import("./common/components/UserSaftey"));
const PrivacyPolicy = lazy(() => import("./common/components/PrivacyPolicy"));
const TermsAndPolicy = lazy(() => import("./common/TermsAndPolicy"));
const Accessibility = lazy(() => import("./common/components/Accessibility"));
const DisputeResolution = lazy(() => import("./common/components/DisputeResolution"));
const CopyrightPolicy = lazy(() => import("./common/components/CopyrightPolicy"));
const UserContentPolicy = lazy(() => import("./common/components/UserContentPolicy"));
const UserAgreement = lazy(() => import("./common/components/UserAgreement"));
const CommunityGuidlines = lazy(() => import("./common/components/CommunityGuidelines"));
const RestrictedContent = lazy(() => import("./common/components/RestrictedContent"));

const UserManagementComponent = lazy(() => import("./modules/UserManagement/UserManagemenComponent"));
const RegistrationComponent = lazy(() => import("./modules/Registration/RegistrationComponent"));
const ProfileComoponent = lazy(() => import('./modules/Profile'));
const EventComponent = lazy(() => import('./modules/Events'));
const ProposalComponent = lazy(() => import('./modules/Proposal/ProposalComponent'));
const RfpComponent = lazy(() => import('./modules/RFP/RfpComponent'));
const BEngageComponent = lazy(() => import('./modules/BEngage/BEngageComponent'));
const BEngageVolunteerComponent = lazy(() => import('./modules/BEngage/BEngageVolunteerComponent'));

export default function App() {
  const dispatch = useDispatch();
  const { users, profile: { modalFlag, modalMessage } } = useSelector((state) => {
    console.log(state) // uncomment when required otherwise make it comment
    return state;
  });

  useEffect(() => {
    dispatch(updateLoginDataFromCookies());
    // socket.disconnect();
  }, [])

  const handleCloseModal = () => {
    dispatch(setModalDetails(false, ''));
    moveToSocialMedia();
  }
  return (
    <>
      {users.isLoggedIn ? (
        <>
          <Routes>
            {/* Profile Routes */}
            <Route path="/user-profile/" element={<ProfileComoponent />} >
              <Route path="create" />
              <Route path="preview" />
              {/* <Route path="about-us" />
              <Route path="address-contacts" />
              <Route path="past-project" />
              <Route path="compliances" />
              <Route path="public-profile" />
              <Route path="past-project/preview/:projectId" /> */}
            </Route>
            <Route path="/profile/" element={<ProfileComoponent />} >
              <Route path=":id" />
            </Route>

            {/* Events Routes */}
            <Route path="/events/" element={<EventComponent />}>
              <Route path="" />
              <Route path="listing" />
              <Route path="create-event" />
              <Route path="create-event/:gid" />
              <Route path="preview" />
              <Route path="preview/:gid" />
              <Route path="publish-event" />
              <Route path='preview/outcome/certificate/:gid' />
              <Route path='preview/completed-volunteer' />
              <Route path='preview/:selectedTab/:gid' />
              <Route path='eventpreview/:gid' />
              <Route path='listing/volunteer-managenment/my-volunteers' />
              <Route path='create-outcome/:gid' />
              <Route path='preview/outcome/:gid' />
              <Route path='newattendence/:gid' />
              <Route path='volunteer-list/:secondaryTab/:tab/:gid' />
            </Route>
            {/* Proposal Routes */}
            <Route path="/proposal" element={<ProposalComponent />}>
              <Route path="start" />
              <Route path="proposalpreview" />
              <Route path="new" />
              <Route path="new/:uniqueId" />
              <Route path="create/outbox/:uniqueId" />
              <Route path="finalpreview/:uniqueId" />
              <Route path="sendproposal/:uniqueId" />
              <Route path="sendproposal" />
              <Route path="sent" />
              <Route path="dashboard" />
              <Route path="response/:uniqueId/:proposal_company_id" />
              <Route path="listing-response/:uniqueId/:proposal_company_id" />
              <Route path="preview/:proposalId" />
              <Route path="Sent-preview/:proposalId" />
              <Route path="ratings/:uniqueId" />
            </Route>
            {/* RFP Routes */}
            <Route path="/rfp" element={<RfpComponent />}>
              <Route path="" />
              <Route path="listing" />
              <Route path="new" />
              <Route path="create" />
              <Route path="create/:rfpId" />
              <Route path="preview/:rfpId" />
              {/* <Route path="preview" /> */}
              <Route path="response" />
              <Route path="new/doc" />
              <Route path="new/doc/:rfpId" />
              <Route path="new/upload" />
              <Route path="new/upload/:rfpId" />
              <Route path="sendrfp" />
              <Route path="sendrfp/:rfpId" />
              <Route path="sent" />
              <Route path="response-sent" />
              <Route path="listing-response/:rfpId" />
              <Route path="sent-preview/:rfpId" />
              <Route path="inbox-preview/:rfpId" />
              <Route path="saved-preview/:rfpId" />
              <Route path="response/:rfpId" />
              <Route path="ratings/:rfpId" />
              <Route path="response-answer" />
              <Route path="response-list" />
              <Route path="response-list/:responseTab/:uniqueId" />
              <Route path='response-details/:responseTab/:uniqueId/:userId' />
              <Route path='view-response' />
              <Route path='all-responses/:rfpId' />
            </Route>
            <Route path="/user" element={<UserManagementComponent />}>
              <Route path='user-management-dashboard' />
            </Route>

            {/* Social Media routes */}
            {/* <Route path="/" element={<CommonErrorPage />} /> */}
            <Route path="/social-media" element={<SocialMediaComponent />} />
            <Route path="/social-media/hashtags/:hashtag" element={<SocialMediaComponent />} />
            <Route path="/social-media/messages" element={<ChatsLayout />} />
            <Route path="/social-media/notifications" element={<Notification />} />
            <Route path="/social-media/community" element={<CommunityLayout />} />
            <Route path="/social-media/contact-us" element={<HelpAndSupport />} />
            <Route path="social-media/events" element={<Events />} />
            <Route path="social-media/events/:gid" element={<EventDetailsNewTab />} />
            <Route path="/social-media/social-impacts/:slug" element={<Schedule7Layout />} />
            <Route path="/social-media/rfps" element={<RfpsListing />} />
            <Route path="/social-media/rfp/:gid" element={<RfpPreview />} />
            <Route path="/social-media/bEngage" element={<SocialBengage />} />
            <Route path="/social-media/bookmark/post" element={<BookMark />} />
            <Route path="/social-media/bookmark/event" element={<BookMark />} />
            <Route path="/social-media/bookmark/conference" element={<BookMark />} />
            <Route path="/social-media/posts/:gid" element={<NotificationPost />} />
            <Route path="/social-media/my-posts" element={<MyPost/>} />

            <Route path="/bEngage" element={<BEngageComponent />}>
              <Route path="" />
              <Route path="listing" />
              <Route path="mydirectory" />
              <Route path="create" />
              <Route path="create/:gid" />
              <Route path="create/:form/:gid" />
              <Route path="preview/:form/:gid" />
              <Route path="admin-preview/:gid" />
              <Route path="admin-public-preview/:gid" />
              <Route path="published" />
              {/* <Route path="public/:gid" /> */}
              <Route path="eticket/:gid" />
              <Route path="eticket" />
              <Route path="checkin-dashboard/:gid" />
              <Route path="registration" />
              <Route path='register/:gid' />
              <Route path='register/:type/:gid' />
              <Route path='register/:type/:gid/:id' />
              <Route path='enquiry/:type/:gid' />
              <Route path='enquiry/:type/:gid/:email' />
              <Route path="registrationComplete" />
              <Route path="registrationComplete/:gid" />
              <Route path="delegates-list/:gid" />
              <Route path="list/:parentType/:type/:gid" />
            </Route>
            <Route path="/volunteer" element={<BEngageVolunteerComponent />}>
              <Route path=":slug" />
            </Route>

            <Route path='/about-us' element={<AboutUs />} />
            <Route path='/contact-us' element={<Contact />} />
            <Route path='/refund-policy' element={<Security />} />
            <Route path="/page-not-found" element={<CommonErrorPage />} />
            <Route path='/terms-condition' element={<TermsAndCondition />} />
            <Route path='/user-Saftey' element={<UserSaftey />} />
            <Route path='/terms-policy' element={<TermsAndPolicy />} />
            <Route path='/accessibility' element={<Accessibility />} />
            <Route path='/dispute-resolution' element={<DisputeResolution />} />
            <Route path='/copyright-policy' element={<CopyrightPolicy />} />
            <Route path='/user-data' element={<UserData />} />
            <Route path='/user-agreement' element={<UserAgreement />} />
            <Route path='/user-content-policy' element={<UserContentPolicy />} />
            <Route path='/restricted-content' element={<RestrictedContent />} />
            <Route path='/privacy-policy' element={<PrivacyPolicy />} />
            <Route path='/community-guidelines' element={<CommunityGuidlines />} />
            <Route path="/user-dashboard" element={<UserDashboard />} />
            <Route path="/status/:uniqueId/:invitation_gid/:conference_gid" element={<InvitationMessagePage/>}/>
            <Route path="/redirect/:email/:pass/:title/:gid" element={<RedirectBEngage />} />
            <Route path='/' element={<Navigate to='/user-dashboard' />} />
            <Route path="/" element={<RegistrationComponent />}>
              <Route path="login" />
              <Route path="/demo" />
              <Route path="set-account-password" />
              <Route path="password-changed-success" />
            </Route>
            <Route path='*' element={<CommonErrorPage />} />
            <Route path="/" element={<NewRegistrationComponent />}>
              <Route path="signup" />
              <Route path="signup-mail-sent" />
              <Route path="forgot-password" />
              <Route path="/reset-password" />
            </Route>
          </Routes>
        </>
      ) : (
        <>
          <Routes>
            <Route path='/about-us' element={<AboutUs />} />
            <Route path='/contact-us' element={<Contact />} />
            <Route path='/refund-policy' element={<Security />} />
            <Route path='/terms-condition' element={<TermsAndCondition />} />
            <Route path='/user-Saftey' element={<UserSaftey />} />
            <Route path='/privacy-policy' element={<PrivacyPolicy />} />
            <Route path='/terms-policy' element={<TermsAndPolicy />} />
            <Route path='/accessibility' element={<Accessibility />} />
            <Route path='/restricted-content' element={<RestrictedContent />} />
            <Route path='/dispute-resolution' element={<DisputeResolution />} />
            <Route path='/copyright-policy' element={<CopyrightPolicy />} />
            <Route path='/user-data' element={<UserData />} />
            <Route path='/user-agreement' element={<UserAgreement />} />
            <Route path='/community-guidelines' element={<CommunityGuidlines />} />
            <Route path='/user-content-policy' element={<UserContentPolicy />} />
            <Route path="social-media/events/:gid" element={<EventDetailsNewTab />} />
            <Route path="/social-media/posts/:gid" element={<NotificationPost/>}/>
            <Route path="/redirect/:email/:pass/:title/:gid" element={<RedirectBEngage />} />
            <Route path="/redirect" element={<CommonRedirectPage />} />
            <Route path="/" element={<RegistrationComponent />}>
              <Route path="/" />
              <Route path="/login" />
              <Route path="/demo" />
              <Route path="/set-account-password" />
              <Route path="/password-changed-success" />
              <Route path="*" />
            </Route>
            <Route path="/" element={<NewRegistrationComponent />}>
              <Route path="signup" />
              <Route path="signup-mail-sent" />
              <Route path="/forgot-password" />
              <Route path="/reset-password" />
            </Route>
            <Route path="/profile/" element={<ProfileComoponent />} >
              <Route path=":id" />
            </Route>
            <Route path="/bEngage" element={<BEngageComponent />}>
              {/* <Route path="public/:gid" /> */}
              <Route path='register/:gid' />
              <Route path='register/:type/:gid' />
              <Route path='register/:type/:gid/:id' />
              <Route path='enquiry/:type/:gid' />
              <Route path='enquiry/:type/:gid/:email' />
              <Route path="registrationComplete" />
              <Route path="registrationComplete/:gid" />
            </Route>
            <Route path="/volunteer" element={<BEngageVolunteerComponent />}>
              <Route path=":slug" />
            </Route>
          </Routes>
        </>
      )}
      <Routes>
        <Route path="/logout" element={<Logout />} />
        {/* <Route path="/social-media" element={<SocialMediaComponent />}/> */}
      </Routes>
      <PermissionDialog open={modalFlag} handleClose={handleCloseModal} title="Permission Removed" message={modalMessage} />
    </>
  );
}