import { Box, Grid, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import SocialMediaCSS from '../../SocialMediaCSS/SocialMedia.module.css';
import { useNavigate } from 'react-router';
import ReduxForm from '../../../../common/components/ReduxForm';
import CommonInput from '../../../../common/components/CommonInput';
import * as clientValidations from '../../../../common/validations';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { submit } from 'redux-form';
import { invitePartner } from '../../actions';
import CommonButton2 from '../../../../common/components/CommonButton2';
import SocialSend from "../../../../assets/icons/SocialSend.svg";
import ImageVector from "../../../../assets/images/No_events_conferences.svg";

const EmptyBookMarkCard = ({ isBookMarkPost, isBookMarkConference, isBookMarkEvents }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { form } = useSelector((state) => state);
    const [errMsg, setErrMsg] = useState('');
    const InviteFormData = form?.Inviteform && form?.Inviteform?.values;
    useEffect(() => {
        if (InviteFormData && InviteFormData?.invite) {
            setErrMsg('');
        }
    }, [InviteFormData]);

    const handleSubmit = () => {
        if (clientValidations.EmailRequired(InviteFormData?.invite) || clientValidations.emailCheck(InviteFormData?.invite)) {
            let error = clientValidations.EmailRequired(InviteFormData?.invite) || clientValidations.emailCheck(InviteFormData?.invite);
            setErrMsg(error);
        } else {
            setErrMsg('');
            dispatch(submit('Inviteform'))
        }
    }
    return (
        <>
            {isBookMarkPost &&
                <Box className={SocialMediaCSS.emptyBookmarkPost}>
                    <Typography className={SocialMediaCSS.emptyBookTypo}>There are no posts to show.</Typography>
                    <Typography className={SocialMediaCSS.emptyBookTypography}>Create a Post or follow someone to see their updates !</Typography>
                    <Typography className={SocialMediaCSS.emptyBookTypography23}>
                        Please send request through
                        &nbsp;<span
                            style={{ color: '#0A97D9', textDecoration: 'underline', cursor: 'pointer' }}
                            onClick={() => navigate('/social-media/community')}
                        >
                            Recommendations
                        </span>&nbsp;
                        or Invite a Connection.
                    </Typography>
                    <Typography className={SocialMediaCSS.emptyBookTypography244}>You can invite partners through their Email Address</Typography>
                    <Box sx={{ width: "50%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
                            <ReduxForm
                                onSubmit={(values) => dispatch(invitePartner(values, setErrMsg))}
                                form="Inviteform"
                                label="Invite"
                                submitButton="Save">

                                <CommonInput
                                    name="invite"
                                    placeholder="Email Address"
                                    keyboardType="text"
                                    placeholderFontSize={'14px'}
                                    type="text"
                                    fullWidth
                                    radius={1}
                                    customHeight={"45px"}
                                    // validate={[clientValidations.EmailRequired, clientValidations.emailCheck]}
                                    maxLength={150}
                                />
                                <Typography className={SocialMediaCSS.inviteError}>{errMsg}</Typography>
                                <Box width="100%" pt={5} sx={{ display: "flex", justifyContent: "center" }}>
                                    <CommonButton2
                                        label="| Send Invite"
                                        name="Invite"
                                        variant="Contained"
                                        startIcon={<img src={SocialSend} />}
                                        type="button"
                                        style={{ fontSize: '15px', borderColor: 'transparent', marginRight: '10px', background: "var(--Button-BG-Blue, linear-gradient(180deg, #0A97D9 0%, #00689D 100%))", color: 'white', padding: '8px 24px' }}
                                        onClick={handleSubmit}
                                    />
                                </Box>
                            </ReduxForm>
                        </Box></Box>
                </Box >}
            {isBookMarkConference &&
                <Box className={SocialMediaCSS.emptyBookmarkPost}>
                    <Box className={SocialMediaCSS?.emptyBookmarkPost2}>
                        <Typography className={SocialMediaCSS.emptyBookTypo}>There are no conferences to show</Typography>
                    </Box>
                    <img src={ImageVector} style={{height:"500px",width:"500px"}} />
                </Box>
            }
            {isBookMarkEvents &&
                <Box className={SocialMediaCSS.emptyBookmarkPost}>
                    <Box className={SocialMediaCSS?.emptyBookmarkPost2}>
                        <Typography className={SocialMediaCSS.emptyBookTypo}>There are no events to show</Typography>
                        
                    </Box>
                    <img src={ImageVector} style={{height:"500px",width:"500px"}} />
                </Box>
            }

        </>
    )
}
export default EmptyBookMarkCard