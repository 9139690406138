import React from 'react';

import { Dialog, DialogTitle, DialogContent, DialogActions, Button, IconButton, useMediaQuery } from '@mui/material';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import theme from '../../assets/material-ui/theme';
import { HeadsetMic } from '@mui/icons-material';

const DeleteAgendaDialog = ({ open, handleClose, handleCancel, handleSubmit, content, title, buttonTextCancel, buttonTextSubmit }) => {
    const isXsScreen = useMediaQuery('(min-width: 960px)');
    return (
        <Dialog open={open} onClose={handleClose} PaperProps={{ sx: { minWidth: isXsScreen ? '500px' : '300px', borderRadius: '20px' } }}>
            <DialogTitle sx={{ color: theme.palette.success.dark, textAlign: 'left', fontWeight: '600', py: 2, borderBottom: '1px solid #ADB5BD' }}>
                {title}
                {/* <IconButton sx={{ position: 'absolute', top: 3, right: 0, color: 'red' }} onClick={handleClose}>
                    <CancelOutlinedIcon />
                </IconButton> */}
            </DialogTitle>
            <DialogContent sx={{ textAlign: 'left', fontWeight: '500', fontFamily: 'poppins', color: theme.palette.success.dark, fontSize: '16px', mt: '20px', py: 3 }}>
                {content}
            </DialogContent>
            <DialogActions sx={{ justifyContent: 'flex-end', padding: '10px 16px', alignItems: "center", gap: "8px", borderTop: '1px solid #ADB5BD' }}>
                {buttonTextCancel && <Button variant='outlined' onClick={handleCancel} sx={{ borderColor: theme.palette.primary.main, color: theme.palette.primary.main, borderWidth: '1px', width: '110px', height: '32px', p: '6px 32px', borderRadius: '27px' }}>{buttonTextCancel}</Button>}
                {buttonTextSubmit && <Button onClick={handleSubmit} variant="contained" sx={{ backgroundColor: theme.palette.primary.main, color: 'white', width: '115px', height: '32px', p: '6px 32px', borderRadius: '27px', background: theme.mainPalette.primary.primary110 }}>
                    {buttonTextSubmit}
                </Button>}
            </DialogActions>
        </Dialog>
    );
};

export default DeleteAgendaDialog;