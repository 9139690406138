import React, { useEffect, useState } from "react";
import { Box, Input, InputAdornment, OutlinedInput, Typography } from "@mui/material";
import CommonCSS from '../Common.module.css';

export const RenderDateInput = ({
    input,
    label,
    placeholder,
    type,
    fullWidth,
    maxLength,
    underlineStyle,
    errorStyle,
    style,
    toolTiptext,
    id,
    pattern,
    defaultValue,
    disabled,
    radius,
    min,
    max,
    value,
    onChange,
    errorWidth,
    onKeyPress,
    meta: { touched, error, warning },
    inputProps,
    errorMessages,
    custom,
    placeholderFontSize,
    placeholderFontWeight,
    customHeight,
    classes = {},
    onDrop,
    onDragOver,
    showCount,
    countFontSize,
    endAdornment
}) => {
    // Internal state for day, month, and year
    const [day, setDay] = useState("");
    const [month, setMonth] = useState("");
    const [year, setYear] = useState("");

    // Handle change for individual inputs
    const handleDayChange = (e) => {
        const value = e.target.value.replace(/\D/g, ""); // Allow only numeric input
        if (value.length <= 2) {
            setDay(value);
            updateFormValue(value, month, year);
        }
    };

    const handleMonthChange = (e) => {
        const value = e.target.value.replace(/\D/g, "");
        if (value.length <= 2) {
            setMonth(value);
            updateFormValue(day, value, year);
        }
    };

    const handleYearChange = (e) => {
        const value = e.target.value.replace(/\D/g, "");
        if (value.length <= 4) {
            setYear(value);
            updateFormValue(day, month, value);
        }
    };

    // Update Redux form value
    const updateFormValue = (d, m, y) => {
        if (d && m && y && y.length === 4) {
            input.onChange(`${y}-${m.padStart(2, "0")}-${d.padStart(2, "0")}`);
        } else {
            input.onChange(""); // Incomplete date, clear the form value
        }
    };

    useEffect(() => {
        if (!input?.value) {
            setDay("");
            setMonth("");
            setYear("");
        }
    }, [input?.value])

    return (
        <>
            <Box className={CommonCSS.formMainDate}>
                <Box className={CommonCSS.formMainDateBox} sx={{ width: '32px' }}>
                    <Typography className={CommonCSS.formMainDateTypo}>Day</Typography>
                    <Input
                        value={day}
                        name={id}
                        onChange={handleDayChange}
                        placeholder="DD"
                        classes={{ root: CommonCSS.formMainDateInput }}
                        sx={{
                            '&:before, &:after': {
                                borderBottom: 'none', // Remove the border-bottom styling
                                transition: 'none',  // Remove the transition effect
                            },
                            '&:hover:not(.Mui-disabled):before': {
                                borderBottom: 'none', // Remove hover effect as well
                            },
                            '&.Mui-disabled:before': {
                                borderBottom: 'none', // Ensure no border for disabled state
                            },
                            '&.Mui-disabled:after': {
                                borderBottom: 'none', // Remove the after-border for disabled state
                            },
                        }}
                        maxLength={2}
                        inputProps={{
                            maxLength: 2, // Restrict to 2 characters
                            style: {
                                textAlign: 'center'
                            }
                        }}
                        disabled={disabled}
                        autoComplete="off"
                    />
                </Box>
                <Box className={CommonCSS.formMainDateDiv}>
                    <Typography className={CommonCSS.formMainDateDivTypo}>/</Typography>
                </Box>
                <Box className={CommonCSS.formMainDateBox} sx={{ width: '44px' }}>
                    <Typography className={CommonCSS.formMainDateTypo}>Month</Typography>
                    <Input
                        value={month}
                        onChange={handleMonthChange}
                        placeholder="MM"
                        name={id}
                        classes={{ root: CommonCSS.formMainDateInput }}
                        sx={{
                            '&:before, &:after': {
                                borderBottom: 'none', // Remove the border-bottom styling
                                transition: 'none',  // Remove the transition effect
                            },
                            '&:hover:not(.Mui-disabled):before': {
                                borderBottom: 'none', // Remove hover effect as well
                            },
                            '&.Mui-disabled:before': {
                                borderBottom: 'none', // Ensure no border for disabled state
                            },
                            '&.Mui-disabled:after': {
                                borderBottom: 'none', // Remove the after-border for disabled state
                            },
                        }}
                        maxLength={2}
                        inputProps={{
                            maxLength: 2, // Restrict to 2 characters
                            style: {
                                textAlign: 'center'
                            }
                        }}
                        disabled={disabled}
                        autoComplete="off"
                    />
                </Box>
                <Box className={CommonCSS.formMainDateDiv}>
                    <Typography className={CommonCSS.formMainDateDivTypo}>/</Typography>
                </Box>
                <Box className={CommonCSS.formMainDateBox} sx={{ width: '42px' }}>
                    <Typography className={CommonCSS.formMainDateTypo}>Year</Typography>
                    <Input
                        placeholder="YYYY"
                        value={year}
                        name={id}
                        onChange={handleYearChange}
                        classes={{ root: CommonCSS.formMainDateInput }}
                        sx={{
                            '&:before, &:after': {
                                borderBottom: 'none', // Remove the border-bottom styling
                                transition: 'none',  // Remove the transition effect
                            },
                            '&:hover:not(.Mui-disabled):before': {
                                borderBottom: 'none', // Remove hover effect as well
                            },
                            '&.Mui-disabled:before': {
                                borderBottom: 'none', // Ensure no border for disabled state
                            },
                            '&.Mui-disabled:after': {
                                borderBottom: 'none', // Remove the after-border for disabled state
                            },
                        }}
                        maxLength={2}
                        inputProps={{
                            maxLength: 4, // Restrict to 2 characters
                            style: {
                                textAlign: 'center'
                            }
                        }}
                        disabled={disabled}
                        autoComplete="off"
                    />
                </Box>
            </Box>
            {touched && error && (
                <Box
                    style={{
                        color: "red",
                        fontSize: "12px",
                        padding: 0,
                        marginTop: '6px',
                        paddingLeft: '10px',
                        wordBreak: "break-word",
                        lineHeight: "14px",
                        maxWidth: errorWidth ? errorWidth : "400px",
                    }}
                >
                    {error}
                </Box>
            )}
        </>
    );
};
export default RenderDateInput;
