import React from 'react';
import Carousel from 'react-material-ui-carousel';
import { FiberManualRecord, FiberManualRecordOutlined } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';
import {Box, Grid} from '@mui/material';

import Slide1 from '../../assets/images/Imgs1.png';
import Slide2 from '../../assets/images/Imgs2.png';
import Slide3 from '../../assets/images/Imgs3.png';
import SlideBG from '../../assets/images/SlideBG.svg';
import { Typography } from '@mui/material';

const useStyles = makeStyles((theme) => ({

}));

const CarouselSpeakerInvitation = () => {
  const classes = useStyles();

  var slideImages = [
    // {
    //     name: "Slide #1",
    //     imageUrl : Slide1,
    // },
    // {
    //     name: "Slide #2",
    //     imageUrl : Slide2,
    // },
    // {
    //     name: "Slide #3",
    //     imageUrl : Slide3,
    // }, 
    {
        name: 'Slide #1',
        imgHTML : `<img src="${Slide1}" width="100%" alt="Image 1" />` 
    },
    {
      name: 'Slide #2',
      imgHTML : `<img src="${Slide2}" width="100%" alt="Image 2" />` 
    },
    {
      name: 'Slide #3',
      imgHTML : `<img src="${Slide3}" width="100%" alt="Image 3" />` 
    }
  ];

  return (<>
      <Carousel navigation={false} navButtonsProps={{style: {display: 'none'}}}
      swipe={false}
      stopAutoPlayOnHover={false}
      interval={2000}
        IndicatorIcon= {<FiberManualRecordOutlined sx={{color:'transparent !important', height:'5px', width:'5px'}}/>}
        indicatorIconButtonProps={{
          style: {
              border: '2px solid #fff',
              borderStyle: 'solid',
              margin: '0 10px'
          }
        }}
        activeIndicatorIconButtonProps={{
          style: {
              backgroundColor: 'white',
              outline: '1px solid white',
              outlineOffset: '4.5px'
          }
        }}
        changeOnFirstRender='true'
        sx={{
            minHeight: { xs: '20vh', sm: '30vh', md: '55vh', lg: '60vh' },
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            justifyContent: { xs: 'start', md: 'center', lg: 'space-between' }
        }}>
        {slideImages.map((item, i) => (
            <Grid container justifyContent='center' sx={{ height: { xs: '340px', md: '420px', lg: '540px' } }} key={i}>
                <Grid item xs={4} sm={3} md={8} lg={5}>
                    <Box 
                        dangerouslySetInnerHTML={{ __html: item.imgHTML }} 
                        sx={{
                            width: '100%', 
                            height: 0, 
                            paddingBottom: '143%', // aspect ratio 626:899
                            position: 'relative',
                            overflow: 'hidden',
                            '& img': {
                                position: 'absolute',
                                top: '0',
                                left: '0',
                                width: '100%',
                                height: '100%',
                                objectFit: { xs: 'contain', sm: 'contain' }
                            }
                        }} 
                    />
                </Grid>
            </Grid>
        ))}
          
      </Carousel> 
    </>
  );
};

export default CarouselSpeakerInvitation
