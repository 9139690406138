import React from 'react'
import { useEffect } from 'react';

const CommonRedirectPage = () => {
    useEffect(() => {
        const userAgent = navigator.userAgent || navigator.vendor || window.opera;

        // Store URLs
        const androidDeepLink = "market://details?id=com.bvokal.bvokal";
        const androidWebLink = "https://play.google.com/store/apps/details?id=com.bvokal.bvokal";
        const iosDeepLink = "itms-apps://apps.apple.com/app/id6466199235";
        const iosURL = "https://apps.apple.com/us/app/bvokal/id6466199235";

        if (/android/i.test(userAgent)) {
            // Attempt deep link first
            window.location.href = androidDeepLink;

            // Fallback to web link after a short delay
            setTimeout(() => {
                window.location.href = androidWebLink;
            }, 1500); // Adjust delay as needed
        } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
            // Redirect to App Store
            window.location.href = iosDeepLink;
            // Fallback to web link after a short delay
            setTimeout(() => {
                window.location.href = iosURL;
            }, 1500); // Adjust delay as needed
        } else {
            // Fallback for unsupported devices
            alert("Unsupported device. Please open this link on a mobile device.");
        }
    }, []);

    return (
        <div>
            <h1>Redirecting...</h1>
            <p>If you are not redirected automatically, please click the appropriate link:</p>
            <ul>
                <li>
                    <a href="https://play.google.com/store/apps/details?id=com.bvokal.bvokal">
                        Download for Android
                    </a>
                </li>
                <li>
                    <a href="https://apps.apple.com/us/app/bvokal/id6466199235">
                        Download for iOS
                    </a>
                </li>
            </ul>
        </div>
    )
}

export default CommonRedirectPage