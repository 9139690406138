import React from 'react'
import ReduxForm from '../../../../common/components/ReduxForm';
import { doTempRegister } from '../../actions';
import { Box, Grid, Typography } from '@mui/material';
import CommonInputNew from '../../../../common/components/CommonInputNew';
import { Link } from 'react-router-dom';
import theme from '../../../../assets/material-ui/theme';
import CommonButtonNew from '../../../../common/components/RectangularInputs/CommonButtonNew';
import * as clientValidations from '../../../../common/validations'
import { reset } from 'redux-form';
import { setTempUserData } from "../../../../store/slices/UserSlice";

const CreateAccountForm = ({ RegCSS, selectedOption, selectedCompanyType, handleSubmit, users, isBusinessSuite, dispatch }) => {
    let initialValues = {
        name: "",
        email: ""
    };
    if(users?.tempUserData){
        initialValues = {
            name: users?.tempUserData?.name,
            email: users?.tempUserData?.email
         }
    }
    return (
        <ReduxForm
            onSubmit={(values) => handleSubmit(values)}
            initialValues={initialValues}
            form="signUP"
            label="SignUP"
            submitButton="Register"
        >
            <Grid container spacing={4}>
                <Grid item xs={12}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sx={{ mt: '22px !important' }}>
                            <Typography className={RegCSS.signUpMailTypo4}>{selectedOption === 'personal' ? 'Full name' : 'Organization name'} <span style={{ color: theme.palette.error.main }}>*</span></Typography>
                            <CommonInputNew
                                name="name"
                                placeholder={selectedOption === 'personal' ? "Enter your full name" : "Enter your Organization's name"}
                                type="text"
                                keyboardType="default"
                                fullWidth
                                validate={[clientValidations.NameRequired, clientValidations.noSpecialCharacters]}
                                maxLength={35}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography className={RegCSS.signUpMailTypo4}>Email address <span style={{ color: theme.palette.error.main }}>*</span></Typography>
                            <CommonInputNew
                                name="email"
                                placeholder={selectedOption === 'personal' ? "Enter your personal email address" : "Enter your Organization's email address"}
                                type="Email"
                                keyboardType="default"
                                fullWidth
                                validate={[clientValidations.EmailRequired, clientValidations.emailCheck, clientValidations.noWhitespaces]}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Box display='flex' justifyContent='center' flexWrap='wrap' px={1}>
                        <span className={RegCSS.createAccSpan}> By clicking "Agree & Register" you agree to the bvokal with an</span>
                        <Link to="/user-agreement" underline="always" className={RegCSS.createAccLink} onClick={() => {dispatch(reset('signUP')); dispatch(setTempUserData({}));}}>
                            user agreement
                        </Link>
                        <span className={RegCSS.createAccSpan2}>,</span>
                        <Link to="/privacy-policy" underline="always" className={RegCSS.createAccLink} onClick={() => {dispatch(reset('signUP')); dispatch(setTempUserData({}));}}>
                            privacy policy
                        </Link>
                        <span className={RegCSS.createAccSpan2}>and</span>
                        <Link to="/terms-policy" underline="always" className={RegCSS.createAccLink} onClick={() => {dispatch(reset('signUP')); dispatch(setTempUserData({}));}}>
                            Cookies policy
                        </Link>
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Box display={'flex'} justifyContent={'center'}>
                        <CommonButtonNew
                            label="Agree & Register"
                            name="Register"
                            type="submit"
                            style={{ background: theme.palette.primary.dark, color: 'white', boxShadow: theme.shadow.softShadow4 }}
                            smallWidth
                        />
                    </Box>
                </Grid>
                {!isBusinessSuite && <Grid item xs={12}>
                    <Box display='flex' justifyContent='center'>
                        <Typography sx={{ fontSize: '14px', color: theme.mainPalette.grey.darkText, fontWeight: theme.typography.fontWeightMedium }}>Already have an account?</Typography>
                        <Link to='/login' underline="always" style={{ marginLeft: 8, color: theme.palette.primary.dark, fontSize: '14px', fontWeight: theme.typography.fontWeightMedium }}>Login</Link>
                    </Box>
                </Grid>}
            </Grid>
        </ReduxForm>
    )
}

export default CreateAccountForm;